const courseAssignment = {
  GET_COURSE_ASSIGNMENT_DEPARTMENT_PROGRAMMES_REQUEST:
    'GET_COURSE_ASSIGNMENT_DEPARTMENT_PROGRAMMES_REQUEST',
  GET_COURSE_ASSIGNMENT_DEPARTMENT_PROGRAMMES_SUCCESS:
    'GET_COURSE_ASSIGNMENT_DEPARTMENT_PROGRAMMES_SUCCESS',
  GET_COURSE_ASSIGNMENT_DEPARTMENT_PROGRAMMES_ERROR:
    'GET_COURSE_ASSIGNMENT_DEPARTMENT_PROGRAMMES_ERROR',

  GET_COURSE_ASSIGNMENT_COURSES_REQUEST:
    'GET_COURSE_ASSIGNMENT_COURSES_REQUEST',
  GET_COURSE_ASSIGNMENT_COURSES_SUCCESS:
    'GET_COURSE_ASSIGNMENT_COURSES_SUCCESS',
  GET_COURSE_ASSIGNMENT_COURSES_ERROR: 'GET_COURSE_ASSIGNMENT_COURSES_ERROR',

  GET_CONTEXT_ASSIGNMENTS_REQUEST: 'GET_CONTEXT_ASSIGNMENTS_REQUEST',
  GET_CONTEXT_ASSIGNMENTS_SUCCESS: 'GET_CONTEXT_ASSIGNMENTS_SUCCESS',
  GET_CONTEXT_ASSIGNMENTS_ERROR: 'GET_CONTEXT_ASSIGNMENTS_ERROR',

  ASSIGN_LECTURERS_TO_COURSE_REQUEST: 'ASSIGN_LECTURERS_TO_COURSE_REQUEST',
  ASSIGN_LECTURERS_TO_COURSE_SUCCESS: 'ASSIGN_LECTURERS_TO_COURSE_SUCCESS',
  ASSIGN_LECTURERS_TO_COURSE_ERROR: 'ASSIGN_LECTURERS_TO_COURSE_ERROR',

  ADD_LECTURER_TO_COURSE_ASSIGNMENT_REQUEST:
    'ADD_LECTURER_TO_COURSE_ASSIGNMENT_REQUEST',
  ADD_LECTURER_TO_COURSE_ASSIGNMENT_SUCCESS:
    'ADD_LECTURER_TO_COURSE_ASSIGNMENT_SUCCESS',
  ADD_LECTURER_TO_COURSE_ASSIGNMENT_ERROR:
    'ADD_LECTURER_TO_COURSE_ASSIGNMENT_ERROR',

  ADD_GROUP_TO_COURSE_ASSIGNMENT_REQUEST:
    'ADD_GROUP_TO_COURSE_ASSIGNMENT_REQUEST',
  ADD_GROUP_TO_COURSE_ASSIGNMENT_SUCCESS:
    'ADD_GROUP_TO_COURSE_ASSIGNMENT_SUCCESS',
  ADD_GROUP_TO_COURSE_ASSIGNMENT_ERROR: 'ADD_GROUP_TO_COURSE_ASSIGNMENT_ERROR',

  EDIT_COURSE_ASSIGNMENT_LECTURERS_REQUEST:
    'EDIT_COURSE_ASSIGNMENT_LECTURERS_REQUEST',
  EDIT_COURSE_ASSIGNMENT_LECTURERS_SUCCESS:
    'EDIT_COURSE_ASSIGNMENT_LECTURERS_SUCCESS',
  EDIT_COURSE_ASSIGNMENT_LECTURERS_ERROR:
    'EDIT_COURSE_ASSIGNMENT_LECTURERS_ERROR',

  REMOVE_LECTURERS_FROM_ASSIGNMENT_REQUEST:
    'REMOVE_LECTURERS_FROM_ASSIGNMENT_REQUEST',
  REMOVE_LECTURERS_FROM_ASSIGNMENT_SUCCESS:
    'REMOVE_LECTURERS_FROM_ASSIGNMENT_SUCCESS',
  REMOVE_LECTURERS_FROM_ASSIGNMENT_ERROR:
    'REMOVE_LECTURERS_FROM_ASSIGNMENT_ERROR',

  SET_SELECTED_ASSIGNMENT_COURSE: 'SET_SELECTED_ASSIGNMENT_COURSE',
  SET_SELECTED_ASSIGNMENT_LECTURERS: 'SET_SELECTED_ASSIGNMENT_LECTURERS',
  SET_SHOW_DELETE_MODAL: 'SET_SHOW_DELETE_MODAL',

  EDITING_COURSE_ASSIGNMENT_RESULT_CATEGORIES_REQUEST:
    'EDITING_COURSE_ASSIGNMENT_RESULT_CATEGORIES_REQUEST',
  EDITING_COURSE_ASSIGNMENT_RESULT_CATEGORIES_SUCCESS:
    'EDITING_COURSE_ASSIGNMENT_RESULT_CATEGORIES_SUCCESS',
  EDITING_COURSE_ASSIGNMENT_RESULT_CATEGORIES_ERROR:
    'EDITING_COURSE_ASSIGNMENT_RESULT_CATEGORIES_ERROR',

  SET_PROGRAMME_CONTEXT_ID: 'SET_PROGRAMME_CONTEXT_ID',
  SET_SHOW_ADD_LECTURER_MODAL: 'SET_SHOW_ADD_LECTURER_MODAL',
  SET_SHOW_ADD_GROUP_MODAL: 'SET_SHOW_ADD_GROUP_MODAL',
  SET_EDIT_ASSIGNMENT_LECTURER: 'SET_EDIT_ASSIGNMENT_LECTURER',
  SET_EDIT_RESULT_CATEGORY: 'SET_EDIT_RESULT_CATEGORY',
  SHOW_EDIT_RESULT_CATEGORIES_MODAL: 'SHOW_EDIT_RESULT_CATEGORIES_MODAL',

  GET_SUBMITTED_RESULTS_REQUEST: 'GET_SUBMITTED_RESULTS_REQUEST',
  GET_SUBMITTED_RESULTS_SUCCESS: 'GET_SUBMITTED_RESULTS_SUCCESS',
  GET_SUBMITTED_RESULTS_ERROR: 'GET_SUBMITTED_RESULTS_ERROR',

  SET_SHOW_VIEW_SUBMITTED_RESULT_MODAL: 'SET_SHOW_VIEW_SUBMITTED_RESULT_MODAL',
  SET_SHOW_RETURN_RESULT_MODAL: 'SET_SHOW_RETURN_RESULT_MODAL',

  RETURN_RESULT_REQUEST: 'RETURN_RESULT_REQUEST',
  RETURN_RESULT_SUCCESS: 'RETURN_RESULT_SUCCESS',
  RETURN_RESULT_ERROR: 'RETURN_RESULT_ERROR',

  MAKING_RESULTS_PROVISIONAL_REQUEST: 'MAKING_RESULTS_PROVISIONAL_REQUEST',
  MAKING_RESULTS_PROVISIONAL_SUCCESS: 'MAKING_RESULTS_PROVISIONAL_SUCCESS',
  MAKING_RESULTS_PROVISIONAL_ERROR: 'MAKING_RESULTS_PROVISIONAL_ERROR',

  GETTING_PROVISIONAL_RESULTS_REQUEST: 'GETTING_PROVISIONAL_RESULTS_REQUEST',
  GETTING_PROVISIONAL_RESULTS_SUCCESS: 'GETTING_PROVISIONAL_RESULTS_SUCCESS',
  GETTING_PROVISIONAL_RESULTS_ERROR: 'GETTING_PROVISIONAL_RESULTS_ERROR',

  SET_PROVISIONAL_RESULTS_CONTEXT: 'SET_PROVISIONAL_RESULTS_CONTEXT',

  SUBMIT_RESULTS_TO_FACULTY_REQUEST: 'SUBMIT_RESULTS_TO_FACULTY_REQUEST',
  SUBMIT_RESULTS_TO_FACULTY_SUCCESS: 'SUBMIT_RESULTS_TO_FACULTY_SUCCESS',
  SUBMIT_RESULTS_TO_FACULTY_ERROR: 'SUBMIT_RESULTS_TO_FACULTY_ERROR',

  GET_HOD_STATISTICS_REQUEST: 'GET_HOD_STATISTICS_REQUEST',
  GET_HOD_STATISTICS_SUCCESS: 'GET_HOD_STATISTICS_SUCCESS',
  GET_HOD_STATISTICS_ERROR: 'GET_HOD_STATISTICS_ERROR',

  SET_SHOW_VIEW_COURSE_ASSIGNMENT_ANALYTICS_RESULTS_MODAL:
    'SET_SHOW_VIEW_COURSE_ASSIGNMENT_ANALYTICS_RESULTS_MODAL',

  GET_FACULTY_ANALYTICS_REQUEST: 'GET_FACULTY_ANALYTICS_REQUEST',
  GET_FACULTY_ANALYTICS_SUCCESS: 'GET_FACULTY_ANALYTICS_SUCCESS',
  GET_FACULTY_ANALYTICS_ERROR: 'GET_FACULTY_ANALYTICS_ERROR',

  GET_QUATEC_RESULTS_REQUEST: 'GET_QUATEC_RESULTS_REQUEST',
  GET_QUATEC_RESULTS_SUCCESS: 'GET_QUATEC_RESULTS_SUCCESS',
  GET_QUATEC_RESULTS_ERROR: 'GET_QUATEC_RESULTS_ERROR',
  SET_QUATEC_RESULTS_CONTEXT: 'SET_QUATEC_RESULTS_CONTEXT',

  GET_QUATEC_ANALYTICS_REQUEST: 'GET_QUATEC_ANALYTICS_REQUEST',
  GET_QUATEC_ANALYTICS_SUCCESS: 'GET_QUATEC_ANALYTICS_SUCCESS',
  GET_QUATEC_ANALYTICS_ERROR: 'GET_QUATEC_ANALYTICS_ERROR',

  SET_SUBMITTED_RESULT_TO_VIEW: 'SET_SUBMITTED_RESULT_TO_VIEW',

  SUBMIT_RESULTS_REQUEST: 'SUBMIT_RESULTS_REQUEST',
  SUBMIT_RESULTS_SUCCESS: 'SUBMIT_RESULTS_SUCCESS',
  SUBMIT_RESULTS_ERROR: 'SUBMIT_RESULTS_ERROR',

  GET_SENATE_RESULTS_REQUEST: 'GET_SENATE_RESULTS_REQUEST',
  GET_SENATE_RESULTS_SUCCESS: 'GET_SENATE_RESULTS_SUCCESS',
  GET_SENATE_RESULTS_ERROR: 'GET_SENATE_RESULTS_ERROR',

  SUBMIT_RESULTS_TO_SENATE_REQUEST: 'SUBMIT_RESULTS_TO_SENATE_REQUEST',
  SUBMIT_RESULTS_TO_SENATE_SUCCESS: 'SUBMIT_RESULTS_TO_SENATE_SUCCESS',
  SUBMIT_RESULTS_TO_SENATE_ERROR: 'SUBMIT_RESULTS_TO_SENATE_ERROR',
  SET_SENATE_RESULTS_CONTEXT: 'SET_SENATE_RESULTS_CONTEXT',

  SET_ACTIVE_COURSE_ASSIGNMENT_HIERARCHY:
    'SET_ACTIVE_COURSE_ASSIGNMENT_HIERARCHY',

  GET_COURSE_UNIT_RESULT_APPROVALS_REQUEST:
    'GET_COURSE_UNIT_RESULT_APPROVALS_REQUEST',
  GET_COURSE_UNIT_RESULT_APPROVALS_SUCCESS:
    'GET_COURSE_UNIT_RESULT_APPROVALS_SUCCESS',
  GET_COURSE_UNIT_RESULT_APPROVALS_ERROR:
    'GET_COURSE_UNIT_RESULT_APPROVALS_ERROR',

  GET_PROGRAMME_RESULT_APPROVALS_REQUEST:
    'GET_PROGRAMME_RESULT_APPROVALS_REQUEST',
  GET_PROGRAMME_RESULT_APPROVALS_SUCCESS:
    'GET_PROGRAMME_RESULT_APPROVALS_SUCCESS',
  GET_PROGRAMME_RESULT_APPROVALS_ERROR: 'GET_PROGRAMME_RESULT_APPROVALS_ERROR',

  getDepartmentProgrammes: (context) => ({
    type: courseAssignment.GET_COURSE_ASSIGNMENT_DEPARTMENT_PROGRAMMES_REQUEST,
    context,
  }),
  getCourseUnitResultApprovals: (context) => ({
    type: courseAssignment.GET_COURSE_UNIT_RESULT_APPROVALS_REQUEST,
    context,
  }),
  getProgrammeResultApprovals: (context) => ({
    type: courseAssignment.GET_PROGRAMME_RESULT_APPROVALS_REQUEST,
    context,
  }),
  setActiveCourseAssignmentHierarchy: (data) => ({
    type: courseAssignment.SET_ACTIVE_COURSE_ASSIGNMENT_HIERARCHY,
    data,
  }),
  getHODStatistics: (context) => ({
    type: courseAssignment.GET_HOD_STATISTICS_REQUEST,
    context,
  }),
  setShowViewCourseAssignmentAnalyticsResults: (show, data = {}) => ({
    type: courseAssignment.SET_SHOW_VIEW_COURSE_ASSIGNMENT_ANALYTICS_RESULTS_MODAL,
    data,
    show,
  }),
  makeResultsProvisional: (data, context) => ({
    type: courseAssignment.MAKING_RESULTS_PROVISIONAL_REQUEST,
    data,
    context,
  }),
  submitResultsToFaculty: (data, context) => ({
    type: courseAssignment.SUBMIT_RESULTS_TO_FACULTY_REQUEST,
    data,
    context,
  }),
  submitResultsToSenate: (data, context) => ({
    type: courseAssignment.SUBMIT_RESULTS_TO_SENATE_REQUEST,
    data,
    context,
  }),
  getSenateResults: (context) => ({
    type: courseAssignment.GET_SENATE_RESULTS_REQUEST,
    context,
  }),
  getFacultyAnalyticsRequest: (context) => ({
    type: courseAssignment.GET_FACULTY_ANALYTICS_REQUEST,
    context,
  }),
  getQuatecAnalytics: (context) => ({
    type: courseAssignment.GET_QUATEC_ANALYTICS_REQUEST,
    context,
  }),
  getQuatecResults: (context) => ({
    type: courseAssignment.GET_QUATEC_RESULTS_REQUEST,
    context,
  }),
  setQuatecResultsContext: (context) => ({
    type: courseAssignment.SET_QUATEC_RESULTS_CONTEXT,
    context,
  }),
  setSubmittedResultToView: (data, show) => ({
    type: courseAssignment.SET_SUBMITTED_RESULT_TO_VIEW,
    data,
    show,
  }),
  submitResults: (data, context) => ({
    type: courseAssignment.SUBMIT_RESULTS_REQUEST,
    data,
    context,
  }),

  getFacultyResults: (context) => ({
    type: courseAssignment.GETTING_PROVISIONAL_RESULTS_REQUEST,
    context,
  }),

  setProvisionalResultsContext: (context) => ({
    type: courseAssignment.SET_PROVISIONAL_RESULTS_CONTEXT,
    context,
  }),

  setFacultyResultsContext: (context) => ({
    type: courseAssignment.SET_PROVISIONAL_RESULTS_CONTEXT,
    context,
  }),

  setSenateResultsContext: (context) => ({
    type: courseAssignment.SET_SENATE_RESULTS_CONTEXT,
    context,
  }),

  setShowViewSubmittedResultModal: (show) => ({
    type: courseAssignment.SET_SHOW_VIEW_SUBMITTED_RESULT_MODAL,
    data: show,
  }),

  setShowUnSubmitResultModal: (show) => ({
    type: courseAssignment.SET_SHOW_RETURN_RESULT_MODAL,
    data: show,
  }),

  unSubmitResult: (data, params) => ({
    type: courseAssignment.RETURN_RESULT_REQUEST,
    data,
    params,
  }),

  editCourseAssignmentResultCategory: (data, context) => ({
    type: courseAssignment.EDITING_COURSE_ASSIGNMENT_RESULT_CATEGORIES_REQUEST,
    data,
    context,
  }),

  setShowEditResultCategoriesModal: (e) => ({
    type: courseAssignment.SHOW_EDIT_RESULT_CATEGORIES_MODAL,
    payload: e,
  }),

  setEditResultCategory: (e) => ({
    type: courseAssignment.SET_EDIT_RESULT_CATEGORY,
    payload: e,
  }),

  getAssignmentCourses: (context) => ({
    type: courseAssignment.GET_COURSE_ASSIGNMENT_COURSES_REQUEST,
    context,
  }),

  getContextAssignments: (context) => ({
    type: courseAssignment.GET_CONTEXT_ASSIGNMENTS_REQUEST,
    context,
  }),

  assignLecturersToCourse: (data, context) => ({
    type: courseAssignment.ASSIGN_LECTURERS_TO_COURSE_REQUEST,
    data,
    context,
  }),

  removeLecturersFromAssignment: (data, context) => ({
    type: courseAssignment.REMOVE_LECTURERS_FROM_ASSIGNMENT_REQUEST,
    data,
    context,
  }),

  addGroupToAssignment: (assignmentId, data, context) => ({
    type: courseAssignment.ADD_GROUP_TO_COURSE_ASSIGNMENT_REQUEST,
    data,
    assignmentId,
    context,
  }),

  editAssignmentLecturer: (assignmentLectureId, data, context) => ({
    type: courseAssignment.EDIT_COURSE_ASSIGNMENT_LECTURERS_REQUEST,
    data,
    assignmentLectureId,
    context,
  }),

  setSelectedCourse: (course) => ({
    type: courseAssignment.SET_SELECTED_ASSIGNMENT_COURSE,
    course,
  }),

  setSelectedLecturers: (data) => ({
    type: courseAssignment.SET_SELECTED_ASSIGNMENT_LECTURERS,
    data,
  }),

  setShowDeleteModal: (data) => ({
    type: courseAssignment.SET_SHOW_DELETE_MODAL,
    data,
  }),

  setProgrammeContextId: (id) => ({
    type: courseAssignment.SET_PROGRAMME_CONTEXT_ID,
    id,
  }),

  setAddLecturerModal: (data) => ({
    type: courseAssignment.SET_SHOW_ADD_LECTURER_MODAL,
    data,
  }),

  setAddGroupModal: (data) => ({
    type: courseAssignment.SET_SHOW_ADD_GROUP_MODAL,
    data,
  }),

  setEditAssignmentLecturer: (editing, dataToEdit) => ({
    type: courseAssignment.SET_EDIT_ASSIGNMENT_LECTURER,
    editing,
    dataToEdit,
  }),

  getSubmittedResults: (courseAssignmentId, params) => ({
    type: courseAssignment.GET_SUBMITTED_RESULTS_REQUEST,
    params,
    courseAssignmentId,
  }),

  addLecturersToAssignment: (assignmentId, data, context) => ({
    type: courseAssignment.ADD_LECTURER_TO_COURSE_ASSIGNMENT_REQUEST,
    assignmentId,
    data,
    context,
  }),
};
export default courseAssignment;
